<template>
  <form @submit.prevent>
    <modal-card
      :title="
        `${isSubscribed ? 'Purchase task credits for' : 'Upgrade'} ${fqdn}`
      "
    >
      <loading v-if="loading" />
      <div v-else class="columns is-multiline is-marginless">
        <div class="column is-12">
          <b-notification type="is-info">
            <p>
              Please note, volume discounts (if applicable) will be shown on the
              "Confirm order" step.
            </p>
          </b-notification>
        </div>

        <div v-if="isSubscribed" class="column is-6">
          <b-message type="is-dark">
            It appears you're on our
            <strong>maintenance-only plan</strong> or
            <strong>you've run out of included task credits</strong>. Please
            purchase a one-off task if you require immediate website assistance,
            or alternatively, <a @click="makeEnquiry">get in touch</a> to
            discuss upgrading your current subscription.
          </b-message>
        </div>
        <div
          v-for="(product, index) in sortedProducts"
          :key="`product-${index}`"
          class="column is-6"
        >
          <product-card
            :product="product"
            :currency="computedCurrency"
            @click.native="selectTerm(product)"
          />
        </div>
      </div>
    </modal-card>
  </form>
</template>

<script>
import { CURRENCY as DEFAULT_CURRENCY } from "@/data/defaults";
import { collection, doc, getDocs, query, where } from "@firebase/firestore";

export default {
  name: "UpgradeModal",
  components: {
    "product-card": () => import("@shared/cart/_productCard")
  },
  props: {
    fqdn: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    isSubscribed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      products: {},
      productTerms: {},
      selected: null
    };
  },
  computed: {
    filteredProducts() {
      return this.$_.filter(this.products, product => {
        if (this.isSubscribed && product.type === "subscription") return false;
        return true;
      });
    },
    sortedProducts() {
      return this.$_.orderBy(this.filteredProducts, ["order"], ["asc"]);
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    siteOwner() {
      return this.$store.getters["user/user"](
        this.$_.get(this.site, "owners[0]")
      );
    },
    computedCurrency() {
      return this.$_.get(this.siteOwner, "currency", DEFAULT_CURRENCY); // GBP is default
    }
  },
  async created() {
    await this.observeSite();
    this.getProducts();
    this.observeSiteOwner();
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", {
      userId: this.$_.get(this.site, "owners[0]")
    });
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  methods: {
    observeSite() {
      return this.$store.dispatch("sites/observeSite", {
        siteId: this.siteId
      });
    },
    observeSiteOwner() {
      return this.$store.dispatch("user/observeUser", {
        userId: this.$_.get(this.site, "owners[0]")
      });
    },
    getProducts() {
      const productsRef = query(
        collection(this.$firestore, `products`),
        where(`categories`, `array-contains`, `core`),
        where("isActive", "==", true)
      );
      getDocs(productsRef).then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(async product => {
            await this.getProductTerms(product);
          });
        }
        this.loading = false;
      });
    },
    getProductTerms(product) {
      const termsRef = query(
        collection(product.ref, "terms"),
        where("isActive", "==", true)
      );
      return getDocs(termsRef).then(snapshot => {
        if (!snapshot.empty) {
          this.$set(
            this.products,
            product.id,
            this.$_.merge({}, product.data(), {
              _productId: product.id
            })
          );
          snapshot.forEach(term => {
            this.$set(
              this.productTerms,
              term.id,
              this.$_.merge({}, term.data(), {
                _termId: term.id,
                _productId: product.id
              })
            );
          });
        }
      });
    },
    selectTerm(product) {
      const terms = this.$_.filter(this.productTerms, term => {
        return term._productId === product._productId;
      });
      if (terms.length > 1) {
        const modal = this.$modal.open({
          component: () => import("@shared/cart/_selectTerm"),
          parent: this,
          width: 540,
          hasModalCard: true,
          props: {
            terms,
            currency: this.computedCurrency
          },
          events: {
            selected: term => {
              this.checkout({
                _termId: term._termId,
                _productId: product._productId
              });
              modal.close();
            }
          }
        });
      } else {
        this.checkout({
          _termId: terms[0]._termId,
          _productId: product._productId
        });
      }
    },
    checkout({ _termId, _productId }) {
      this.$emit("close");
      this.$modal.open({
        component: () => import("@shared/cart/_orderModal"),
        parent: this.$parent.$parent,
        width: 640,
        hasModalCard: true,
        canCancel: [],
        props: {
          siteId: this.siteId,
          userId: this.userId,
          productId: _productId,
          productTermId: _termId,
          currency: this.computedCurrency
        }
      });
    },
    makeEnquiry() {
      this.$emit("close");
      this.$store.dispatch("tasks/openAddTaskModal", {
        userId: this.userId,
        siteId: this.siteId,
        taskRef: doc(this.$firestore, `taskTypes/enquiry`),
        default: `Please can I upgrade my subscription for ${this.fqdn}?`
      });
    }
  }
};
</script>
